<template>
  <div class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" style="height:100%">
    <div class="sidenav-header d-flex align-items-center">
      <b-navbar-brand href="#">
        <!-- <img :src="logo" class="navbar-brand-img" alt="Sidebar logo"> -->
        <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info">
          <i class="ni ni-chart-bar-32"></i>
        </div>
        <span class="margin-left" v-show="!collapse">管理后台</span>
      </b-navbar-brand>
    </div>
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <el-menu
        default-active="0"
        unique-opened
        class="el-menu-vertical-demo"
        :collapse="collapse"
        @open="handleOpen"
        @close="handleClose">
        <el-submenu v-for="(link, index) in menus" :key="link.name + index" :index="index+''">
          <template slot="title">
            <i :class="active==index ? 'el-icon-folder-opened': 'el-icon-folder'"></i>
            <span :class="{'text-bold': active==index}">{{ link.label }}</span>
          </template>
          <el-menu-item v-for="(subLink, index) in link.childNodes" :key="subLink.name + index"  :index="index+''" @click="handleMenu(link, subLink)">
            {{ subLink.label }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    collapse: false,
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: '/img/brand/green.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    menus: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  data () {
    return {
      active: 0
    }
  },
  methods: {
    handleMenu (folder, menu) {
      this.$router.push('/'+folder.name + menu.url)
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.active = key
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth
    this.minimizeSidebar()
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style lang="scss">
.menu-bar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
}
</style>