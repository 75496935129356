<template>
  <div class="flex justify-between padding-sm" style="cursor: pointer;border-bottom: 1px solid #e6e6e6;">
    <!-- {{chat}} -->
    <div class="flex align-center">
      <el-avatar shape="square" :size="40" :src="avatar"/>
    </div>
    <div class="flex align-center margin-left-sm" style="flex:1">
      <div>
        <div>
          <span class="text-lg">{{ chat.uid }}</span>
        </div>
        <div class="margin-top-xs text-gray text-cut" style="width:150px" v-html="content">

        </div>
      </div>
    </div>
    <div class="flex align-center margin-left-sm">
      <div>
        <div class="text-gray text-sm">
          {{ chat.lastMessageTime | chatTime }}
        </div>
        <div class="text-center">
          <el-badge class="newMsgCount" :value="chat.nonReadCount" :max="99" v-show="chat.nonReadCount > 0"></el-badge>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    chat: {
      type: Object,
      default: () => {
        return {}
      }
    },
    nongReadCount: 0,
    lastMessageTime: ''
  },
  computed: {
    avatar () {
      if (this.chat.avatar && this.chat.avatar != '') {
        return this.chat.avatar
      }
      return '/img/client_avatar.jpg'
    },
    content () {
      console.log('content', this.chat)
      if (this.chat.contentType=='image') {
        return '[图片]'
      }
      if (this.chat.contentType=='file') {
        return '[文件]'
      }
      return this.chat.content
    }
  },
  methods: {
  }
}
</script>