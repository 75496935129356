import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 上下分列表
export function listUpDownBalance (query) {
  return jsonFetch.post('/balance/listUpDownBalance', query)
}
// 通过
export function accept(id, notes) {
  return formFetch.post('/balance/accept', Qs.stringify({id, notes}))
}
// 拒绝
export function reject(id, notes) {
  return formFetch.post('/balance/reject', Qs.stringify({id, notes}))
}