<template>
  <div>
    <!-- <notifications></notifications> -->
    <menu-bar :menus="navMenu" :collapse.sync="isCollapse">
    </menu-bar>
    <!-- <side-bar>
      <template slot="links">
        <sidebar-item
          v-for="folder in navMenu"
          :key="folder.id"
          :link="{
            name: folder.label,
            icon: 'ni ni-calendar-grid-58 text-red'
          }">
          <sidebar-item
            v-for="menu in folder.childNodes"
            :key="menu.id"
            :link="{ name: menu.label, path: '/'+folder.name + menu.url }">
          </sidebar-item>
        </sidebar-item>
      </template>
    </side-bar> -->
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" :menuCollapsed.sync="isCollapse"></dashboard-navbar>
      <div>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }
  import MenuBar from '@/components/MenuBar'
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import { mapGetters } from 'vuex'
  export default {
    components: {
      MenuBar,
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data () {
      return {
        isCollapse: false
      }
    },
    computed: {
      ...mapGetters ([
        'navMenu'
      ])
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
      console.log('dashbord mounted')
      this.$store.dispatch('token/GetAccount').then(res => {
        this.$store.dispatch('dashboard/GetInfo')
        // this.$store.dispatch('InitWebSocket', res.data.userInfo)
        if (res.success) {
          this.$swal.fire({
            title: '登录成功, '+res.data.userInfo.username,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success'
          });
        }
      })
    }
  };
</script>
<style lang="scss">
</style>
