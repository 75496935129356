<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem  class="flex align-end">
        <router-link to="/">
          <i class="fa fa-home" style="font-size: 1rem"></i>
        </router-link>
    </BreadCrumbItem>
    <template v-if="$route.meta.folder">
      <span class="flex align-end margin-lr-xs text-white">/</span>
      <BreadCrumbItem
        class="flex align-end"
        style="display:inline-block"
      >
        <span class="text-white d-inline-block">{{ $route.meta.folder }}</span>
      </BreadCrumbItem>
    </template>
    <template v-if="$route.meta.name && $route.meta.name!=''">
      <span class="flex align-end margin-lr-xs text-white">/</span>
      <BreadCrumbItem
        class="flex align-end"
        style="display:inline-block"
      >
        <span class="text-white text-xl">{{ $route.meta.name }}</span>
      </BreadCrumbItem>
    </template>
  </bread-crumb>
</template>

<script>
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';

  export default {
    name: 'route-breadcrumb',
    components: {
      BreadCrumb,
      BreadCrumbItem
    },
    created () {
      console.log('route', this.$route)
    },
    methods: {
      getBreadName(route) {
        return route.name;
      }
    }
  };
</script>

<style scoped></style>
