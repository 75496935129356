<template>
  <el-tooltip :content="text" effect="dark" placement="bottom" style="padding: 0 12px;">
    <div>
      <i v-if="socket.isConnected" class="fa fa-wifi text-white"></i>
      <i v-else class="fa fa-unlink text-red"></i>
    </div>
  </el-tooltip>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      socket: state => state.socket.socket
    }),
    text () {
      if (this.socket.isConnected) {
        return '网络正常'
      } else {
        if (this.socket.reconnectError) {
          return '网络异常'
        }
        return '网络未连接'
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style>
</style>
