import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/user/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/user/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/user/${id}/detail`)
}

export function create (user) {
  return jsonFetch.post(`/user/create`, user)
}

export function modify (user) {
  return jsonFetch.post(`/user/${user.id}/modify`, user)
}

export function getProfile () {
  return formFetch.post(`/user/get-profile`)
}

export function modifyProfile (user) {
  return jsonFetch.post(`/user/modify-profile`, user)
}

export function del (id) {
  return formFetch.post(`/user/${id}/delete`)
}

export function selectRole (id) {
  return formFetch.post(`/user/${id}/select-role`)
}

export function grantRole (userId, menus) {
  return jsonFetch.post(`/user/${userId}/grant-role`, menus)
}