import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 会员列表
export function list (query) {
  return jsonFetch.post('/member/list', query)
}
// 会员详情
export function find (uid) {
  return formFetch.post('/member/find', Qs.stringify({uid}))
}
// 修改备注
export function saveNotes (uid, notes) {
  return formFetch.post('/member/saveNotes', Qs.stringify({uid, notes}))
}
// 修改帐号密码
export function saveProfile (member) {
  return jsonFetch.post('/member/saveProfile', member)
}
export function switchStatus (id, available) {
  return formFetch.post(`/member/${id}/status`, Qs.stringify({ available }))
}
// 下级列表
export function listChildren (query) {
  return jsonFetch.post('/member/listChildren', query)
}
// 改分
export function changeBalance (uid, amount) {
  return formFetch.post('/member/changeBalance', Qs.stringify({uid, amount}))
}
// 账变记录
export function balanceHis (query) {
  return jsonFetch.post('/member/balanceHis', query)
}
// 反佣记录
export function commissionHis (query) {
  return jsonFetch.post('/member/commissionHis', query)
}
// 投注记录
export function orderList (query) {
  return jsonFetch.post('/member/orderList', query)
}
// 投注详情
export function orderDetail (uid, issue) {
  return formFetch.post('/member/orderDetail', Qs.stringify({uid, issue}))
}
// 投注详情列表
export function orderDetailList (query) {
  return jsonFetch.post('/member/orderDetailList', query)
}
// 刷新第三方额度
export function queryThirdPartBalance (uid) {
  return formFetch.post('/member/queryThirdPartBalance', Qs.stringify({uid}))
}
// 回收第三方额度
export function retrieveThirdPartBalance (uid) {
  return formFetch.post('/member/retrieveThirdPartBalance', Qs.stringify({uid}))
}