import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from '@/store'

import '/public/css/style.scss'
import '/public/css/element-variables.scss'
import '/public/css/_toasts.scss'
import '/public/css/colorui/main.scss'
import '/public/css/colorui/icon.css'
import 'font-awesome/css/font-awesome.min.css'

import lang from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
locale.use(lang)
// router setup
import router from './routes/router';
import './permission' // permission control
// plugin setup
Vue.use(DashboardPlugin);

import swal from 'sweetalert2';
Vue.prototype.$swal = swal

//load filters
import filters from './filter/common'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// websocket
import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, '', {
  connectManually: true
})

// vac
import vueAwesomeCountdown from 'vue-awesome-countdown'
Vue.use(vueAwesomeCountdown, 'vac')

// 微信emoji插件
import { emoji2string, genString2emoji } from "@/components/EmojiPicker/util.js"
Vue.prototype.$string2emoji = genString2emoji('/img/qqface.png')
Vue.prototype.$emoji2string = emoji2string

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});
