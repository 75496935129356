import Vue from 'vue'
import { listMessage, readMessage } from '@/api/chat'
import { find } from '@/api/member'
const chat = {
  state: {
    newChats: [],
    openedChats: [],
    historyChats: [],
    activedChat: '',
    msgList: {},
    newMsgTime: {}
  },
  getters: {
    GET_NEW_CHATS (state) {
      return state.newChats
    },
    GET_OPENED_CHATS (state) {
      return state.openedChats
    },
    GET_HISTORY_CHATS (state) {
      return state.historyChats
    },
    GET_ACTIVED_CHAT (state) {
      return state.activedChat
    },
    GET_MSG_LIST (state) {
      return state.msgList
    },
    GET_NEW_MSG_TIME (state) {
      return state.newMsgTime
    }
  },
  mutations: {
    SET_NEW_CHATS: (state, chats) => {
      state.newChats = chats
    },
    ADD_NEW_CHATS: (state, chats) => {
      state.newChats.unshift(...chats)
    },
    SET_HISTORY_CHATS: (state, chats) => {
      state.historyChats = chats
    },
    ADD_HISTORY_CHATS: (state, chats) => {
      state.historyChats.unshift(...chats)
    },
    SET_ACTIVED_CHAT: (state, uid) => {
      state.activedChat = uid
    },
    OPEN_NEW_CHAT: (state, uid) => {
      state.newChats.some((tempChat, index) => {
        if (tempChat.uid === uid) {
          let opend = false
          state.openedChats.some(openedChat => {
            if (openedChat.uid === uid) {
              opend = true
            }
          })
          if (!opend) {
            find(uid).then(res => {
              if (res.success) {
                tempChat.userInfo = res.data
                tempChat.nonReadCount = 0
                state.openedChats.push(tempChat)
              }
            })
          }
          state.newChats.splice(index, 1)
          state.activedChat = uid
          return true
        }
      })
    },
    OPEN_HISTORY_CHAT: (state, uid) => {
      state.historyChats.some((tempChat, index) => {
        if (tempChat.uid === uid) {
          let opend = false
          state.openedChats.some(openedChat => {
            if (openedChat.uid === uid) {
              opend = true
            }
          })
          if (!opend) {
            find(uid).then(res => {
              if (res.success) {
                tempChat.userInfo = res.data
                tempChat.nonReadCount = 0
                state.openedChats.push(tempChat)
              }
            })
          }
          state.activedChat = uid
          return true
        }
      })
    },
    OPEN_CONTACT_CHAT: (state, uid) => {
      let opend = false
      state.openedChats.some(openedChat => {
        if (openedChat.uid === uid) {
          opend = true
        }
      })
      if (!opend) {
        let tempChat = {
          uid: uid
        }
        find(uid).then(res => {
          if (res.success) {
            tempChat.userInfo = res.data
            tempChat.nonReadCount = 0
            state.openedChats.push(tempChat)
          }
        })
      }
      state.activedChat = uid
    },
    CLOSE_CHAT: (state, uid) => {
      for (let i=0;i<state.openedChats.length;i++) {
        let tempChat = state.openedChats[i]
        if (tempChat.uid===uid) {
          state.openedChats.splice(i, 1)
          delete state.msgList[uid]
          delete state.newMsgTime[uid]
          break
        }
      }
    },
    ADD_MESSAGE: (state, newMsgList) => {
      // console.log('ADD_MESSAGE', message)
      if (newMsgList==null || newMsgList.length==0) {
        return
      }
      let uid = newMsgList[0].uid
      // 插入消息
      let msgList = state.msgList[uid]
      if (msgList) {
        msgList.push(...newMsgList)
        Vue.set(state.msgList, uid, msgList)
        Vue.set(state.newMsgTime, uid, new Date().getTime())
      }
      // 未激活的会话标题显示未读数量
      if (uid != state.activedChat) {
        state.openedChats.some((item, index) => {
          if (item.uid === uid) {
            item.nonReadCount += newMsgList.length
            Vue.set(state.openedChats, index, item)
            return true
          }
        })
      }
    },
    ADD_HISTORY_MESSAGE: (state, historyMsgList) => {
      if (historyMsgList==null || historyMsgList.length==0) {
        return
      }
      let uid = historyMsgList[0].uid
      let msgList = state.msgList[uid]
      if (msgList) {
        msgList.unshift(...historyMsgList)
        Vue.set(state.msgList, uid, msgList)
      }
    },
    SET_MESSAGE_LIST: (state, {uid, msgList}) => {
      // console.log('SET_MESSAGE_LIST', uid, msgList)
      Vue.set(state.msgList, uid, msgList)
      Vue.set(state.newMsgTime, uid, new Date().getTime())
    },
    CLEAR_NONREAD_COUNT: (state, uid) => {
      state.openedChats.some((item, index) => {
        if (item.uid === uid) {
          item.nonReadCount = 0
          Vue.set(state.openedChats, index, item)
          return true
        }
      })
    }
  },
  actions: {
    setNewChats ({ commit }, chats) {
      commit('SET_NEW_CHATS', chats)
    },
    addNewChats ({ commit }, chats) {
      commit('ADD_NEW_CHATS', chats)
    },
    openNewChat ({ commit }, uid) {
      return new Promise((resolve, reject) => {
        listMessage(uid, 0, 20).then(res => {
          if (res.success) {
            commit('OPEN_NEW_CHAT', uid)
            commit('SET_MESSAGE_LIST', {uid: uid, msgList: res.data.reverse()})
            resolve(res)
          }
        })
      })
    },
    closeChat ({ commit }, uid) {
      commit('CLOSE_CHAT', uid)
    },
    setHistoryChats ({ commit }, chats) {
      commit('SET_HISTORY_CHATS', chats)
    },
    addHistoryChats ({ commit }, chats) {
      commit('ADD_HISTORY_CHATS', chats)
    },
    openHistoryChat ({ commit }, uid) {
      return new Promise((resolve, reject) => {
        listMessage(uid, 0, 20).then(res => {
          if (res.success) {
            commit('OPEN_HISTORY_CHAT', uid)
            commit('SET_MESSAGE_LIST', {uid: uid, msgList: res.data.reverse()})
            resolve(res)
          }
        })
      })
    },
    openContactChat ({ commit }, uid) {
      return new Promise((resolve, reject) => {
        listMessage(uid, 0, 20).then(res => {
          if (res.success) {
            commit('OPEN_CONTACT_CHAT', uid)
            commit('SET_MESSAGE_LIST', {uid: uid, msgList: res.data.reverse()})
            resolve(res)
          }
        })
      })
    },
    addMessage ({ commit }, newMsgList) {
      commit('ADD_MESSAGE', newMsgList)
    },
    loadMoreMessage ({ commit }, {uid, start, limit}) {
      return new Promise((resolve, reject) => {
        listMessage(uid, start, limit).then(res => {
          if (res.success) {
            if (res.data.length > 0) {
              commit('ADD_HISTORY_MESSAGE', res.data.reverse())
            }
            resolve(res)
          }
        })
      })
    },
    readMessage ({ commit }, uid) {
      return new Promise((resolve, reject) => {
        readMessage(uid).then(res => {
          if (res.success) {
            commit('CLEAR_NONREAD_COUNT', uid)
            resolve()
          }
        })
      })
    },
  }
};
export default chat;
