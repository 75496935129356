<template>
  <div class="contact-wrapper">
    <main class="main">
      <div v-if="memberList.length > 0" class="item-list">
        <div class="item flex" v-for="(member, index) in memberList" :key="index" @click="selectChat(member)">
          <member-info :member="member" readOnly showUid></member-info>
        </div>
      </div>
      <div v-else-if="memberList.length == 0" class="empty-wrapper">
        <div class="content">
          <i class="iconfont fa fa-commenting-o"></i>
          <p class="title">无记录</p>
        </div>
      </div>
    </main>
    <div class="text-center bg-white">
      <el-pagination
        size="small"
        background
        layout="total, sizes, prev, pager, next"
        :total="totalCount"
        :page-size="limit"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import MemberInfo from '@/views/Components/MemberInfo'
import { getContacts } from '@/api/chat'
export default {
  components: {
    MemberInfo
  },
  props: ['keyWord'],
  data() {
    return {
      memberList: [],
      start: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.GET_USERINFO
    }
  },
  mounted () {
    // this.getData()
  },
  watch: {
    'keyWord': {
      handler: function(val) {
        this.start = 0
        this.getData()
      },
      immediate: true
    }
  },
  methods: {
    getData (){
      getContacts(this.start, this.limit, this.keyWord).then(res => {
        if (res.success) {
          this.totalCount = res.totalCount
          this.memberList = res.data
        } else {
          this.$message.error('加载通讯录出错，刷新页面后可重新加载')
        }
      })
    },
    handleCurrentChange (currentPage) {
      this.start = this.limit * (currentPage - 1)
      this.getData()
    },
    selectChat (en) {
      this.$emit('selectChat', en)
    }
  }
}
</script>
<style lang="less">
.contact-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0px;
  // margin-bottom: 50px;
  .page-bar {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 8px 10px;
  }
  &>.header {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;

    .kf-info-wrapper {
      position: relative;
      width: 150px;
      height: 50px;
      padding: 0 20px;

      .kf-avatar {
        width: 50px;
        height: 50px;
      }

      .kf-name {
        font-size: 16px;
      }
    }

    .client-info-wrapper {
      p:first-child {
        margin-bottom: 5px;
      }

      .fa {
        margin-right: 10px;
      }
    }
  }

  &>.main {
    height: calc(~'100% - 111px');
    position: relative;
    .item-list {
      height: 100%;
      overflow-y: auto;

      .item {
        padding: 10px;
        border-bottom: 1px solid #e6e6e6;
        cursor: pointer;

        &.active {
          background-color: #0095ff;

          .info-wrapper .first-p .name,
          .info-wrapper .second-p .lastMsgContent,
          .info-wrapper .first-p .lastMsgTime {
            color: #eaf4fb;
          }

          .iconfont {
            display: initial !important;
          }
        }

        .followicon-wrapper {
          position: relative;
          float: left;
          width: 25px;
          height: 100%;

          .iconfont {
            display: none;
            font-size: 12px;
            color: #eaf4fb;

            &.active {
              display: initial;
              color: #f9ce1d;
            }
          }
        }

        .platicon-wrapper {
          position: relative;
          float: left;
          width: 50px;
          height: 100%;

          .header-img {
            padding: 10px;
            font-size: 16px;
            color: #fff;
            border-radius: 50%;
            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
            }
            &.bg0 {
              background-color: #ef7777;
            }

            &.bg1 {
              background-color: #00bcd4;
            }

            &.bg2 {
              background-color: #009688;
            }

            &.bg3 {
              background-color: #ffc107;
            }

            &.bg4 {
              background-color: #ff5722;
            }
          }
        }

        .info-wrapper {
          position: relative;
          float: left;
          width: 250px;
          padding-top: 5px;
          padding-left: 20px;

          .first-p {
            clear: both;
            padding-top: 11px;

            .name {
              display: inline-block;
              float: left;
              width: 50%;
              font-size: 14px;
              color: #3e3e3e;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              text-align: left;
              font-weight: bolder;
            }

            .lastMsgTime {
              float: right;
              font-size: 12px;
              color: #8d8d8d;
            }
          }

          .second-p {
            clear: both;
            padding-top: 5px;
            line-height: 1.2;

            .lastMsgContent {
              display: inline-block;
              width: 150px;
              margin-top: 3px;
              font-size: 12px;
              color: #8d8d8d;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: left;
              overflow: hidden;
            }

            .newMsgCount {
              position: relative;
              top: -3px;
              float: right;

              .el-badge__content {
                border: 1px solid #ffffff00;
              }
            }
          }
        }
      }
    }

    .empty-wrapper {
      font-size: 16px;
      color: #3e3e3e;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .content {
        width: 170px;
        height: 200px;
        margin: 0px auto;
        text-align: center;
        color: #867c7c;

        .iconfont {
          font-size: 90px;
        }

        .title {
          margin-top: 25px;
        }
      }
    }
  }
}
</style>