import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import Chat from '@/views/chat/index'
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');


let dynamicRoutes = [];

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { 
      'ignoreAuth': true,
      'keepAlive': false
     }
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/home',
    name: 'Dashboard layout',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Dashboard,
        meta: {
          folder: '仪表盘'
        }
      }
    ]
  },
  { path: '/chat', name: 'Chat', component: Chat },
  { path: '/chat/:uid', component: Chat, props: true },
  { path: '*', component: NotFound }
];

export default routes;
