const APP_TOKEN = 'satoken'

// 获取token
export function getToken () {
  return localStorage.getItem(APP_TOKEN)
}
// 设置 token
export function setToken (token) {
  return localStorage.setItem(APP_TOKEN, token)
}
// 删除 token
export function removeToken () {
  return localStorage.clear(APP_TOKEN)
}