<template>
  <div class="imServer-wrapper">
    <main class="imServer-main">
      <!--未读会话-->
      <new-chat class="item im-record" @selectChat="loadNewChat"></new-chat>
      <!--聊天区域-->
      <chat-main class="item im-chat" @sendMsg="sendMsg"></chat-main>
      <!--历史会话及通讯录-->
      <!-- <right-panel class="item im-work-table" @selectChat="loadHistoryChat"></right-panel> -->
      <div class="right-panel">
        <el-input placeholder="输入uid/昵称/备注查询" v-model="keyWord" class="input-with-select" clearable @clear="handleSearch">
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"><i class="el-icon-time margin-right-xs" />历史对话</span>
            <history-chat ref="history_chat" @selectChat="loadHistoryChat" v-loading="loading"></history-chat>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"><i class="cuIcon-peoplelist margin-right-xs" />通讯录</span>
            <contact :keyWord="keyWordSubmit" @selectChat="loadContactChat" v-loading="loading"></contact>
          </el-tab-pane>
        </el-tabs>
      </div>
    </main>
  </div>
</template>

<script >
import { mapState } from 'vuex'
import NewChat from './components/new-chat.vue';
import ChatMain from './components/chat-main.vue';
import HistoryChat from './components/history-chat.vue';
import Contact from './components/contact.vue';
import {CHAT_SOCKET_URL} from '@/utils/fetch'
import {listUnreadChat, listHistoryChat, read} from '@/api/chat'
export default {
  components: {
    NewChat,
    ChatMain,
    HistoryChat,
    Contact
  },
  props: ['uid'],
  data() {
    return {
      chat: {},
      loading: false,
      nickName: '',
      cc: 0,
      websocket: null,
      heartbeat_timer: null,
      keyWord: '',
      keyWordSubmit: ''
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.token.userInfo,
      newChats: state => state.chat.newChats,
      openedChats: state => state.chat.openedChats,
      historyChats: state => state.chat.historyChats,
      activedChat: state => state.chat.activedChat
    }),
  },
  watch: {
    uid: {
      handler: function (val) {
        if (val) {
          console.log('uid change', val)
          this.$store.dispatch('openContactChat', parseInt(val))
        }
      },
      immediate: true
    }
  },
  mounted() {
    document.title = '客服后台'
    this.getAccount()
    this.getData()
  },
  methods: {
    handleSearch () {
      this.keyWordSubmit = this.keyWord
      this.getData()
    },
    getData () {
      this.loading = true
      listUnreadChat().then(res => {
        if (res.success) {
          this.$store.dispatch('setNewChats', res.data)
          listHistoryChat(0, 10, this.keyWord).then(res => {
            this.loading = false
            if (res.success) {
              this.$store.dispatch('setHistoryChats', res.data)
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    loadNewChat (chat) {
      this.$store.dispatch('openNewChat', chat.uid)
      this.$store.dispatch('readMessage', chat.uid)
    },
    loadHistoryChat (chat) {
      this.$store.dispatch('openHistoryChat', chat.uid)
    },
    loadContactChat (chat) {
      this.$store.dispatch('openContactChat', chat.uid)
    },
    sendMsg(msg) {
      console.log('imServer sendMsg', msg)
      let wsMessage = {'type': 'chat', 'event': 'sendChatMessage', content: msg}
      this.websocket.send(JSON.stringify(wsMessage))
      this.$store.dispatch('addMessage', [msg])
    },
    getAccount() {
      let _this = this
      this.$store.dispatch('token/GetAccount').then(() => {
        _this.loginServer()
      })
    },
    init() {
      this.cc = 0;
      this.initSocket()
    },
    initSocket() {
      let _this = this
      _this.websocket = new WebSocket(CHAT_SOCKET_URL + "/" + _this.userInfo.id);
      _this.websocket.onopen = function (evt) { _this.onOpen(evt) };
      _this.websocket.onclose = function (evt) { _this.onClose(evt) };
      _this.websocket.onmessage = function (evt) { _this.onMessage(evt) };
      _this.websocket.onerror = function (evt) { _this.onError(evt) };
    },
    onOpen(evt) {
      console.log('websocket connected')
      let _this = this
      if (_this.heartbeat_timer) {
        clearInterval(this.heartbeat_timer)
      }
      _this.heartbeat_timer = setInterval(function () { _this.keepalive() }, 5000);
    },
    onClose(evt) {
      console.log('websocket closeed')
      this.cc = 3;
    },
    onError(evt) {
      console.log('websocket error')
      this.cc = 3;
    },
    doSend(message) {
      this.websocket.send(message);
    },
    disConnect() {
      if (this.websocket != null) {
        this.websocket.close();
        this.websocket = null;
      }
    },
    keepalive() {
      let _this = this
      _this.cc++;
      if (_this.cc > 6) {
        _this.cc = 3;
        _this.disConnect();
        _this.initSocket();
        return;
      }
      _this.websocket.send(JSON.stringify({
        'type': 'chat',
        'event': 'heartBeat'
      }));
    },
    onMessage(evt) {
      this.cc = 0;
      console.log('onMessage: ' + evt.data)
      let data = JSON.parse(evt.data)
      if (data.type === 'chat') {
        if (data.event == 'heartBeat') {
          return
        }
        if(data.event == 'receiveChatMessage') {
          this.receiveMessage(JSON.parse(data.content))
        }
      }
    },
    // 接收到聊天消息
    receiveMessage(message) {
      const that = this
      let chatOpend = false
      this.openedChats.some(item => {
        if (item.uid === message.uid) {
          that.$store.dispatch('addMessage', [message])
          // 当前显示的会话，直接设为已读
          if (that.activedChat === message.uid) {
            that.$store.dispatch('readMessage', message.uid)
          }
          chatOpend = true
          return true
        }
      })
      let chatExists = false
      if (!chatOpend) {
        this.newChats.some(item => {
          if (item.uid===message.uid) {
            // console.log('message', message)
            item.nonReadCount ++
            that.$set(item, 'content', message.content)
            that.$set(item, 'contentType', message.contentType)
            that.$set(item, 'lastMessageTime', message.createTime)
            chatExists = true
            return true
          }
        })
        if (!chatExists) {
          this.$store.dispatch('addNewChats', [Object.assign(message, {
              nonReadCount: 1,
              lastMessageTime: message.createTime
            })])
        }
      }
    },
    loginServer() {
      this.disConnect();
      this.init();
    },
    destroyed() {
      this.disConnect()
    }
  }
};
</script>

<style lang="less">
@import '/public/css/chat.less';
.imServer-wrapper {
  #common-wrapper();
}

.imServer-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  .el-badge__content {
    top: 5px!important;
  }
  .imServer-main {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;

    &>.item {
      border-right: 1px solid #e6e6e6;
      height: 100%;
    }

    &>.im-record {
      width: 300px;
    }

    &>.right-panel {
      width: 300px;
    }

    &>.im-chat {
      width: calc(~'100% - 600px')
    }
  }

  .right-panel {
    width: 100%;
    height: 100%;
    .search {
      display: flex;
      flex-direction: row;
    }
    .el-tabs--border-card {
      height: 100%;
    }
    .el-tabs--border-card>.el-tabs__content {
      height: 100%;
      padding: 0px;
    }
    .el-tab-pane{
      height: 100%;
    }
  }
}
</style>
