const state = {
  loading: false,
}
const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading
  }
}
const actions = {
  Loading ({ commit }, message) {
    commit('SET_LOADING', message?message:'加载中...')
  },
  UnLoading ({ commit }) {
    commit('SET_LOADING', false)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
