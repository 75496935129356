import router from './routes/router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
NProgress.configure({ showSpinner: false})

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  NProgress.start()
  const token = getToken()
  // const token = Cookies.get('satoken-admin')
  // console.log('to.path', to.path)
  if (token) {
    if (to.path === '/login') {
      next({ path: '/home' })
      NProgress.done()
    } else {
      const hasMenu = store.getters.navMenu
      if (hasMenu && hasMenu.length>0) {
        //console.log('hasMenu', router.options.routes)
        next()
      } else {
        //console.log('do not hasMenu')
        store.dispatch('token/GetAccount').then(res => {
          if (res.success) {
            let asyncRoutes = []
            let navMenus = store.getters.navMenu
            // console.log('navMenus', navMenus)
            if (navMenus) {
              navMenus.forEach(folder => {
                folder.childNodes.forEach(menu => {
                  let path = folder.name + '/' + menu.name +'/index.vue'
                  // console.log('-path->', path)
                  asyncRoutes.push({
                    path: '/' + folder.name + '/' + menu.name,
                    component: (resolve) => require([`@/views/${path}`], resolve),
                    meta: {
                      folder: folder.label,
                      name: menu.label
                    }
                  })
                })
              })
              //console.log('add asyncRoutes')
              // 动态添加子路由，需要添加到根路由上
              //console.log('router.options', router.options)
              let rootRoute = router.options.routes.find(r => r.path === '/');
              rootRoute.children = asyncRoutes
              router.addRoutes([rootRoute])
              router.options.routes.push(...asyncRoutes)
            }
            next({ ...to, replace: true })
          } else {

          }
        }).catch(err => {
          console.error(err) 
          NProgress.done()
        })
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
