<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{'bg-info navbar-dark': type === 'default'}"
  >
    <div class="sidenav-toggler text-white"
          :class="{'active': !menuCollapsed }"
          @click="minimizeSidebar">
      <div class="text-xxl">
        <i class="el-icon-s-unfold" v-if="menuCollapsed"></i>
        <i class="el-icon-s-fold" v-else></i>
      </div>
    </div>
    
    <el-button v-if="parseInt(info.msg)>0" icon="el-icon-service" round class="service-btn margin-left" @click="openChat">
      客服系统<el-badge :value="info.msg" class="margin-left-xs" style="margin-top:8px"></el-badge>
    </el-button>
    <el-button v-else icon="el-icon-service" round class="margin-left" @click="openChat">
      客服系统
    </el-button>
    
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <div class="text-white">
        人数：{{info.online_user}}
      </div>
      <div class="text-white margin-left margin-right">
        余额：{{info.sum_balance}}
      </div>
      <el-tooltip content="上分请求" effect="dark" placement="bottom">
        <router-link to="/balance/balance-up" class="text-white nav-link">
          <li class="nav-item">
            <el-badge :value="info.balance_UP" :hidden="info.balance_UP<=0">
              <i class="fa fa-arrow-up"></i>
            </el-badge>
          </li>
        </router-link>
      </el-tooltip>
      <el-tooltip content="下分请求" effect="dark" placement="bottom">
        <router-link to="/balance/balance-down" class="text-white nav-link">
          <li class="nav-item">
            <el-badge :value="info.balance_DOWN" :hidden="info.balance_DOWN<=0">
              <i class="fa fa-arrow-down"></i>
            </el-badge>
          </li>
        </router-link>
      </el-tooltip>
      <el-tooltip content="投注监控" effect="dark" placement="bottom">
        <router-link to="/game-manage/monitor" class="text-white nav-link">
          <li class="nav-item">
            <i class="el-icon-monitor"></i>
          </li>
        </router-link>
      </el-tooltip>
      <audio id="msgAlert" src="/media/msg.mp3" muted />
      <!-- <web-socket></web-socket> -->
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" src="/img/theme/team-0.jpg">
                  </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{userInfo.username}}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>

          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </b-dropdown-header>
          <b-dropdown-item href="#!" @click.native="profileVisible = true">
            <i class="ni ni-single-02"></i>
            <span>帐户信息</span>
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <b-dropdown-item href="#!" @click.native="handleLogout">
            <i class="ni ni-user-run"></i>
            <span>安全退出</span>
          </b-dropdown-item>

        </template>
      </base-dropdown>
    </b-navbar-nav>
    <user-profile v-if="profileVisible" :visible.sync="profileVisible" @submit="handleUpdateProfile"></user-profile>
  </base-nav>
</template>
<script>
import { mapState } from 'vuex'
import { BaseNav } from '@/components'
import WebSocket from '@/components/WebSocket'
import UserProfile from './UserProfile'
import { getToken } from '@/utils/auth'
import { PAGE_URL } from '@/utils/fetch'
export default {
  components: {
    BaseNav,
    WebSocket,
    UserProfile
  },
  props: {
    menuCollapsed: false,
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    ...mapState({
      info: state => state.dashboard.info,
      userInfo: state => state.token.userInfo
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      profileVisible: false,
      timer: null
    };
  },
  watch: {
    'menuCollapsed': {
      handler: function(val) {
        this.$emit('update:menuCollapsed', val)
      },
      immediate: true
    },
    'info': {
      handler: function(val) {
        if (val.balance_UP > 0 || val.balance_DOWN > 0 || val.msg > 0 ) {
          this.playMsgAlert()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.refreshInfo()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    minimizeSidebar() {
      this.menuCollapsed = !this.menuCollapsed
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    handleLogout () {
      this.$store.dispatch('token/SignOut').then(() => {
        this.$disconnect()
        this.$router.push('/login')
      })
    },
    // 打开客服页面，如果已打开则激活标签页
    openChat () {
      if (!window.chatPage || window.chatPage.closed) {
        window.chatPage = window.open(PAGE_URL+'chat', 'chatPage')
      }
      window.chatPage.focus()
    },
    handleUpdateProfile () {
      this.$bvToast.toast('修改成功', {
        title: '提示',
        solid: true,
        variant: 'success',
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-center'
      })
    },
    refreshInfo () {
      const that = this
      this.timer = setInterval (function () {
        that.$store.dispatch('dashboard/GetInfo')
      }, 2000)
    },
    playMsgAlert () {
      const msgAlert = document.getElementById('msgAlert')
      msgAlert.play()
    }
  }
};
</script>
<style lang="scss">
.service-btn {
  padding: 2px 10px !important;
}
</style>