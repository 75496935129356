<!-- 会话记录 -->
<template>
  <div class="imRecord-wrapper">
    <header class="header">
      <div class="kf-info-wrapper">
        <img class="kf-avatar" src="/img/chat/im_server_avatar.png" />
        <span class="kf-name position-h-v-mid">{{ userInfo.username }}</span>
      </div>
      <div class="client-info-wrapper">
      </div>
    </header>
    <main class="main">
      <div v-if="chats.length > 0" class="item-list">
        <chat-item v-for="(chat, index) in chats" :key="index" @click.native="selectChat(chat)" :chat="chat" />
      </div>
      <div v-else class="empty-wrapper">
        <div class="content">
          <i class="iconfont fa fa-commenting-o"></i>
          <p class="title">当前没有新会话</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChatItem from './chat-item'
export default {
  components: {
    ChatItem
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.token.userInfo,
      chats: state => state.chat.newChats
    })
  },
  watch: {

  },
  methods: {
    selectChat(chat) {
      this.$emit('selectChat', chat)
    }
  }
};
</script>

<style lang="less">
.imRecord-wrapper {
  width: 100%;
  height: 100%;
  border: 0px;

  &>.header {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;

    .kf-info-wrapper {
      position: relative;
      width: 150px;
      height: 50px;
      padding: 0 20px;

      .kf-avatar {
        width: 50px;
        height: 50px;
      }

      .kf-name {
        font-size: 16px;
        margin-left: 10px;
      }
    }

    .client-info-wrapper {
      p:first-child {
        margin-bottom: 5px;
      }

      .fa {
        margin-right: 10px;
      }
    }
  }

  &>.main {
    height: calc(~'100% - 50px');
    position: relative;

    .item-list {
      height: calc(~'100% - 46px');
      overflow-y: auto;

      .item {
        position: relative;
        height: 70px;
        padding: 0px;
        border-bottom: 1px solid #e6e6e6;
        cursor: pointer;

        &.active,
        &:hover {
          background-color: #0095ff;

          .info-wrapper .first-p .name,
          .info-wrapper .second-p .lastMsgContent,
          .info-wrapper .first-p .lastMsgTime {
            color: #eaf4fb;
          }

          .iconfont {
            display: initial !important;
          }
        }

        .followicon-wrapper {
          position: relative;
          float: left;
          width: 25px;
          height: 100%;

          .iconfont {
            display: none;
            font-size: 12px;
            color: #eaf4fb;

            &.active {
              display: initial;
              color: #f9ce1d;
            }
          }
        }

        .platicon-wrapper {
          position: relative;
          float: left;
          width: 50px;
          height: 100%;

          .header-img {
            padding: 10px;
            font-size: 16px;
            color: #fff;
            border-radius: 50%;
            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
            }
            &.bg0 {
              background-color: #ef7777;
            }

            &.bg1 {
              background-color: #00bcd4;
            }

            &.bg2 {
              background-color: #009688;
            }

            &.bg3 {
              background-color: #ffc107;
            }

            &.bg4 {
              background-color: #ff5722;
            }
          }
        }

        .info-wrapper {
          position: relative;
          float: left;
          width: 185px;
          padding-top: 5px;
          padding-left: 20px;

          .first-p {
            clear: both;
            padding-top: 11px;

            .name {
              display: inline-block;
              float: left;
              width: 50%;
              font-size: 14px;
              color: #3e3e3e;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              text-align: left;
              font-weight: bolder;
            }

            .lastMsgTime {
              float: right;
              font-size: 12px;
              color: #8d8d8d;
            }
          }

          .second-p {
            clear: both;
            padding-top: 5px;
            line-height: 1.2;

            .lastMsgContent {
              display: inline-block;
              width: 150px;
              margin-top: 3px;
              font-size: 12px;
              color: #8d8d8d;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: left;
              overflow: hidden;
            }

            .newMsgCount {
              position: relative;
              top: -3px;
              float: right;

              .el-badge__content {
                border: 1px solid #ffffff00;
              }
            }
          }
        }
      }
    }

    .empty-wrapper {
      font-size: 16px;
      color: #3e3e3e;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .content {
        width: 170px;
        height: 200px;
        margin: 0px auto;
        text-align: center;
        color: #867c7c;

        .iconfont {
          font-size: 90px;
        }

        .title {
          margin-top: 25px;
        }
      }
    }
  }
}</style>
