<template>
  <div class="p-2" style="width:100%">
    <div class="float-left">
      <el-pagination
        background
        :hide-on-single-page="false"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="query.limit"
        :page-sizes="[5,10,100,1000,2000]"
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <div class="float-right" style="width:150px;">
      <span style="font-size: 13px;margin-right:5px">每页条数</span>
      <el-input v-model="query.limit" type="number" size="small" :min="5" style="width:80px"></el-input>
    </div>
  </div>
</template>

<script>
import { Pagination, Input } from 'element-ui';
export default {
  name: 'BasePagination',
  components: {
    [Pagination.name]: Pagination,
    [Input.name]: Input
  },
  props: {
    total: Number,
    query: {
      type: Object,
      default: () => {
        return {
          start: 0,
          limit: 10
        }
      }
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  watch: {
    'query.limit': {
      handler: function (val) {
        this.query.start = this.query.limit * (this.currentPage -1)
        this.$emit('size-change')
      }
    }
  },
  methods: {
    handleSizeChange (size) {
      this.query.limit = size
      this.query.start = this.query.limit * (this.currentPage -1)
      this.$emit('size-change')
    },
    handleCurrentChange (currentPage) {
      if (currentPage == null) {
        return
      }
      this.query.start= this.query.limit * (currentPage - 1)
      this.$emit('current-change')
    },
  }
}
</script>