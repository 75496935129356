<!-- 聊天主窗口 -->
<template>
  <div class="imChat-wrapper">
    <main class="imChat-main">
      <!-- 聊天框区域 -->
      <el-tabs type="border-card" v-model="activedChat" closable stretch @tab-remove="handleTabRemove" @tab-click="handleTabClick">
        <el-tab-pane :name="chat.uid" v-for="chat in openedChats">
          <div slot="label" class="padding-xs" style="height:50px">
            <div class="flex">
              <div class="flex align-center">
                <el-badge v-if="chat.nonReadCount>0" :value="chat.nonReadCount" :max="99">
                  <el-avatar :src="chat.userInfo.avatar?chat.userInfo.avatar:'/img/client_avatar.jpg'" :size="36"></el-avatar>
                </el-badge>
                <el-avatar v-else :src="chat.userInfo.avatar?chat.userInfo.avatar:'/img/client_avatar.jpg'" :size="36"></el-avatar>
              </div>
              <div class="flex align-center margin-left-xs">
                <div class="text-left">
                  <div class="text-lg" style="height:20px;line-height:20px">{{chat.userInfo.uid}} - {{chat.userInfo.nickName}}</div>
                  <div class="text-sm text-gray" style="height:20px;line-height:20px">备注: {{chat.userInfo.memberNotes?chat.userInfo.memberNotes:'空值'}}</div>
                </div>
              </div>
            </div>
          </div>
          <common-chat ref="common_chat" v-if="chat.uid" :chat="chat" :oprRoleName="'server'"
            @sendMsg="sendMsg">
          </common-chat>
        </el-tab-pane>
      </el-tabs>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import commonChat from './common_chat.vue';
export default {
  components: {
    commonChat
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.token.userInfo,
      openedChats: state => state.chat.openedChats,
    }),
    activedChat: {
      get () {
        return this.$store.getters.GET_ACTIVED_CHAT
      },
      set (val) {
        this.$store.commit('SET_ACTIVED_CHAT', val)
      }
    }
  },
  watch: {
    activedChat (uid) {
      if (uid) {
        this.$store.dispatch('readMessage', uid)
      }
    }
  },
  methods: {
    handleTabClick (o) {
      // console.log('handleTabClick', o)
    },
    handleTabRemove (uid) {
      this.$store.dispatch('closeChat', uid)
    },
    /**
     * 发送消息
     * @param {Object} rs 回调对象
     */
    sendMsg (rs) {
      // console.log('rs', rs)
      var msg = rs.msg;
      msg.role = 'server';
      const that = this
      this.$emit('sendMsg', Object.assign(msg, {
        source: 'SERVER',
        adminId: that.userInfo.id,
        createTime: new Date()
      }))
      if (rs.successCallbcak) {
        rs.successCallbcak()
      }

    },

    goEnd: function () {
      this.$refs.common_chat.goEnd();
    },

  },
  mounted() { }
};
</script>
<style lang="less">
.imChat-wrapper {
  width: 100%;
  height: 100%;
  .el-tabs__item {
    display: flex;
    flex-direction: row;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    background-color: #e5e4e4;
    .el-icon-close {
      height: 20px;
      margin-top: 12px;
      font-size: 20px;
    }
  }
  .el-tabs--border-card>.el-tabs__header {
    background-color: #e5e4e4;
  }
  .el-tabs__item.is-active {
    background-color: #f5f5f5!important;
  }
  .el-tabs, .el-tabs__content, .el-tab-pane, .tabpanel {
    height: 100%;
  }
  .el-tabs__nav-scroll {
    border-bottom: 1px solid #e6e6e6;
  }
  .el-tabs__content {
    background-color: #f5f5f5;
    padding: 0!important;
  }
  .cu-chat .cu-item {
    padding: 15px 15px 20px;
  }
  .cu-chat .cu-item > .main {
    margin: 0 14px;
    max-width: 60%;
  }
  .cu-chat .cu-item .date {
    bottom: 0px;
    left: 70px;
  }
  .cu-chat .self .date {
    bottom: 0px;
    left: auto;
    right: 70px;
  }
  .cu-chat .cu-item .date {
    left: 15px;
  }
  .cu-chat .self .date {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    left: auto;
    right: 15px;
  }
  .bg-green {
    background-color: #95ec69!important;
    color: #000;
  }
  p {
    margin: 0!important;
    line-height: 1!important;
    word-break: break-all;
  }
  .el-textarea__inner {
    border: 0;
    border-radius: 0;
    font-size: 15px;
    background-color: #f5f5f5;
  }
  .imChat-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 16px;

    span {
      margin-right: 20px;
    }

    .on-line {
      color: #70ed3a;
    }

    .off-line {
      color: #bbbbbb;
    }
  }

  .imChat-main {
    height: 100%;
  }
}</style>

