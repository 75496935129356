import Vue from 'vue'
import {WEB_SOCKET_URL} from '@/utils/fetch'
const state = {
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
  }
}

const mutations = {
  SOCKET_ONOPEN (state, event)  {
    Vue.prototype.$socket = event.currentTarget
    state.socket.isConnected = true
  },
  SOCKET_ONCLOSE (state, event)  {
    state.socket.isConnected = false
  },
  SOCKET_ONERROR (state, event)  {
    console.error(state, event)
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE (state, message)  {
    state.socket.message = message
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.info(state, count)
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
}

const actions = {
  InitWebSocket ({ commit, dispatch }, userInfo) {
    console.log('----InitWebSocket----')
    const vm = new Vue()
    vm.$connect(WEB_SOCKET_URL + userInfo.username, {
      reconnection: true, // (Boolean) whether to reconnect automatically (false)
      reconnectionAttempts: Infinity, // (Number) number of reconnection attempts before giving up (Infinity),
      reconnectionDelay: 5000, // (Number) how long to initially wait before attempting a new (1000)
      store: this,
      // format: 'json'
    })
    vm.$options.sockets.onopen = () => {
      console.log(new Date() + ' websocket open')
    }
    vm.$options.sockets.onmessage = (data) => {
      console.log('websocket msg', data)
      let msg = JSON.parse(data.data)
      if (msg.event == 'progress') { // 更新进度条
        commit('app/SET_PROGRESS', msg)
      } else if (msg.event == 'GetInfo') {
        dispatch('dashboard/GetInfo')
      } else {  // 消息提醒
        commit('user/ADD_NEW_MSG', msg)
      }
    }
    vm.$options.sockets.onclose = () => {
      console.log(new Date() + ' websocket closed')
      delete vm.$options.sockets.onopen
      delete vm.$options.sockets.onclose
      delete vm.$options.sockets.onmessage
    }
  },
  SendMessage: function(context, message) {
    // .....
    Vue.prototype.$socket.sendObj(message)
    // .....
  },
}

export default {
  // vue-native-websocket事件提交store需要在事件名中指定命名空间，故不启用
  // namespaced: true,
  state,
  mutations,
  actions
}
