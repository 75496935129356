<template>
  <div class="flex align-center">
    <el-avatar :src="avatar" :size="size"/>
    <div class="margin-left-sm flex align-center">
      <div>
        <div class="flex" :style="{height: size/2 +'px', lineHeight: size/2 +'px'}">{{member.uid}} ({{ member.userName }}) </div>
        <div class="flex margin-top-xs" :style="{height: size/2 +'px', lineHeight: size/2 +'px'}"">
          {{ member.nickName }}
          <div title="点击修改备注">
            <span v-if="readOnly" class="text-red margin-left-xs">({{ !member.memberNotes?'空值':member.memberNotes }})</span>
            <el-popover v-else title="修改备注" placement="right-start">
              <el-link slot="reference" type="danger" class="margin-left-xs">
                ({{ !member.memberNotes?'空值':member.memberNotes }})
              </el-link>
              <el-input v-model="member.memberNotes">
                <el-button slot="append" size="sm" @click="saveNotes">保存</el-button>
              </el-input>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveNotes } from '@/api/member'
export default {
  props: {
    member: {
      type: Object,
      default: () => {return {
        userName: '',
        nickName: '',
        avatar: ''
      }}
    },
    size: {
      type: Number,
      default: 30
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showUid: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    avatar () {
      if (this.member.avatar) {
        return '/static/system/' + this.member.avatar
      }
      return '/img/client_avatar.jpg'
    }
  },
  watch: {
    notes (val) {
      this.$emit('update:notes', val)
    },
  },
  methods: {
    saveNotes () {
      saveNotes(this.member.uid, this.member.memberNotes).then(res => {
        if (res.success) {
          this.hideNotes()
          this.$emit('saved')
          this.$bvToast.toast('修改成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 2000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    hideNotes () {
      this.$root.$emit('bv::hide::popover')
    },
  }
}
</script>
<style>
.el-link {
  font-size: 0.8125rem;
}
</style>