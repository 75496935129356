// 金额过滤器，包含2位小数
const balance = function (value) {
  // console.log('value='+value)
  if (!value) return '0';
  value = value + ''
  var value2Array = value.split('.');
  var intPart = value2Array[0]; // 获取整数部分
  // console.log('intPart='+intPart)
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
  var floatPart = '.00'; // 预定义小数部分

  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString(); // 拿到小数部分
    if (floatPart.length > 2) {
      return intPartFormat + '.' + floatPart.substring(0,2);
    } else if (floatPart.length === 1) { // 补0,实际上用不着
      return intPartFormat + '.' + floatPart + '0';
    } else {
      return intPartFormat + '.' + floatPart;
    }
  } else {
    return intPartFormat + floatPart;
  }
}
// 订单状态
const orderState = function (val) {
  var text = val
  switch (val) {
    case 0:
      text = '待结算'
      break
    case 1:
      text = '赢'
      break
    case 2:
      text = '输'
      break
    case 3:
      text = '撤单'
      break
  }
  return text;
}
// 账变类型
const tradeType = function (val) {
  var text = val
  switch (val) {
    case 'WIN':
      text = '中奖'
      break
    case 'ORDER':
      text = '下注'
      break
    case 'CANCEL':
      text = '撤单'
      break
    case 'MANUAL':
      text = '手动改分'
      break
    case 'UP':
      text = '上分'
      break
    case 'DOWN':
      text = '下分'
      break
    case 'COMMISSION':
      text = '返佣'
      break
    case 'RETURN_WATER':
      text = '返水'
      break
    case 'BONUS':
      text = '活动奖励'
      break
  }
  return text;
}

// 活动类型
const activityType = function (val) {
  var text = val
  switch (val) {
    case 'checkIn':
      text = '签到'
      break;
    case 'charge':
      text = '充值'
      break;
    case 'singleCharge':
        text = '单笔充值'
        break;
    case 'order':
      text = '流水'
      break;
    case 'lose':
      text = '亏损'
      break;
    case 'invite':
      text = '邀请'
      break;
    case 'turnTable':
      text = '大转盘'
      break;
    case 'experience':
      text = '体验金'
      break;
  }
  return text;
}

import dayjs from 'dayjs'
const chatTime = function (val) {
  if (val == null) {
    return ''
  }
  if (dayjs().isSame(dayjs(val), 'day')) {
    return dayjs(val).format('HH:mm');
  }
  if (dayjs().subtract(1, 'day').isSame(dayjs(val), 'day')) {
    return '昨天'
  }
  return dayjs(val).format('YY/MM/DD');
}

export default {
  balance, orderState, tradeType, activityType, chatTime
}
