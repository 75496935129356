import { getInfo } from '@/api/dashboard'

const state = {
  info: {
    online_user: 0,
    sum_balance: 0,
    balance_UP: 0,
    balance_DOWN: 0
  },
}
const mutations = {
  SET_INFO: (state, info) => {
    state.info = info
  },
}
const actions = {
  GetInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(res => {
          if (res.success) {
            commit('SET_INFO', res.data)
            resolve(res)
          } else {
            reject(res.message)
          }
        }).catch(() => {
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}