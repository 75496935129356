<template>
  <div class="el-upload-list--picture-card">
    <li tabindex="0" class="el-upload-list__item is-success" :style="{'height': height ? height+'px' : '100px', 'width': 'auto'}">
      <!-- <img 
        :src="url" 
        alt=""
        @click="clickHandler"
        class="el-upload-list__item-thumbnail"
        :style="{'height': height ? height+'px' : '100px', 'width': width ? width+'px': 'auto'}"> -->
      <el-image
        style="width: 50px; height: 50px"
        :src="url"
        @click="clickHandler"
        class="el-upload-list__item-thumbnail flex justify-center align-center"
        :style="{'height': height ? height+'px' : '100px', 'width': width ? width+'px': 'auto'}">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture" style="font-size:32px"></i>
        </div>
      </el-image>
      <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click.stop="showViewer=true">
          <i class="el-icon-zoom-in"></i>
        </span>
      </span>
      <image-viewer 
        :z-index="2000" 
        :initial-index="0" 
        v-if="showViewer" 
        :on-close="closeViewer" 
        :url-list="[url]"/>
    </li>
  </div>
</template>

<script>
import ImageViewer from './image-viewer.vue'
let prevOverflow = '';
export default {
  name: 'BaseImage',
  props: ['src', 'width', 'height'],
  components: { ImageViewer },
  data () {
    return {
      loading: false,
      showViewer: false,
      url: ''
    }
  },
  created () {
    this.url = this.src
  },
  methods: {
    handleLoad () {
      this.loading = false
    },
    handleError () {
      this.loading = false
    },
    handleRefresh () {
      if (this.url) {
        this.loading = true
        this.url = ''
        this.url = this.url.substring(0, this.url.indexOf('?')>-1?this.url.indexOf('?'):this.url.length) + '?ts=' + new Date().getTime()
      }
    },
    clickHandler() {
      // don't show viewer when preview is false
      if (!this.preview) {
        return;
      }
      // prevent body scroll
      prevOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      this.showViewer = true;
    },
    closeViewer() {
      document.body.style.overflow = prevOverflow;
      this.showViewer = false;
    }
  }
}
</script>

<style lang="scss">
.el-upload-list--picture-card .el-upload-list__item {
  margin: 0!important;
}
</style>