<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    title="修改资料"
    @close="close">
    <el-form :model="model" :rules="rules" ref="form" label-position="top">
      <el-form-item prop="username" label="用户名">
        <div class="text-bold" style="margin-top:2px">{{ model.username }}</div>
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input v-model="model.password" placeholder="若不修改请留空"></el-input>
      </el-form-item>
      <el-form-item prop="serviceGroup" label="客服分组">
        <el-radio v-model="model.serviceGroup" label="online">在线客服</el-radio>
        <el-radio v-model="model.serviceGroup" label="welfare">福利客服</el-radio>
        <el-radio v-model="model.serviceGroup" label="">无</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { getProfile, modifyProfile } from '@/api/system/user'
export default {
  name: 'UserProfile',
  props: ['visible'],
  data () {
    return {
      model: {
        id: '',
        username: '',
        password: '',
        serviceGroup: 0
      },
      rules: {
        password: [
          { message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  created () {
    getProfile().then(res => {
      if (res.success) {
        this.model = res.data
      }
    })
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          modifyProfile(this.model).then(res => {
            if (res.success) {
              this.$emit('submit')
              this.close()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">

</style>