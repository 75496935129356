import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function listMessage(uid, start, limit) {
  return formFetch.post('/chat/message/' + uid, Qs.stringify({ start, limit }))
}
export function readMessage(uid) {
  return formFetch.post('/chat/readServerMessage/' + uid)
}
export function getMessageList(chatId) {
  return formFetch.get('/chat/message/' + chatId)
}
export function listUnreadChat() {
  return formFetch.post('/chat/service/unread')
}
export function listHistoryChat(start, limit, keyWord) {
  return formFetch.post('/chat/service/history', Qs.stringify({ start, limit, keyWord }))
}
export function getContacts(start, limit, keyWord) {
  return formFetch.post('/chat/contacts', Qs.stringify({ start, limit, keyWord }))
}
export function read(messageId) {
  return formFetch.post('chat/read/' + messageId)
}
export function upload(file) {
  return formFetch.post('chat/upload', file)
}
export function listQuickReply() {
  return formFetch.post('/chat/quickReply')
}