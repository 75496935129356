import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 登录
export function signIn (username, password, captcha) {
  return formFetch.post('/auth/login', Qs.stringify({ username, password, captcha }))
}
// 登出
export function signOut () {
  return formFetch.post('/auth/logout')
}
// 获取帐户信息
export function getAccount () {
  return jsonFetch.post('/auth/getAccount')
}