/**
 * 判断地址栏URL类型
 * return 0=本地 1=ip 2=顶级域名 3=二级或以上域名
 */
export function typeOfUrl (url) {
  var test = url.replace("https://", "").replace("http://", "").split("/")[0].split(":")[0]
  if (test.indexOf('localhost')==0 || test.indexOf('127.0.0.1')==0) {
    return 0
  }
  var reg = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
  if(reg.test(test)) {
    return 1
  } else {
    var firstDomain = test.split('.')[0]
    if (firstDomain === 'www' || firstDomain === 'crm') {
      return 2
    } else {
      return 3
    }
  }
}

/**
 * 从浏览器地址栏获取协议，http或https
 */
export function getProtocol (url) {
  var protocol = ''
  if (url.indexOf('https')===0) {
    protocol = 'https'
  } else {
    protocol = 'http'
  }
  console.log('parse url:protocol='+protocol)
  return protocol
}

/**
 * 从浏览器地址栏获取域名/ip
 */
export function getHost (url) {
  var host = url.replace("https://", "").replace("http://", "").split('/')[0].split(':')[0]
  console.log('parse url:host='+host)
  return host
}

/**
 * 从浏览器地址栏获取端口
 */
export function getPort (url) {
  var port = ''
  var a = url.replace("https://", "").replace("http://", "").split('/')[0].split(':')
  if (a.length==2) {
    port = ':'+a[1]
  }
  console.log('parse url:port='+port)
  return port
}
