<!-- 聊天记录 -->
<template>
  <div class="common_chat-wrapper">
    <div class="common_chat-inner">
      <!-- 聊天记录 -->
      <div v-if="loaded" class="cu-chat msg-list" id="common_chat_main">
        <div v-show="loading" v-loading="loading" class="text-center margin-top text-lg" element-loading-spinner="el-icon-loading" style="height:30px;width:100%"></div>
        <div v-show="finished" class="item sys flex justify-center text-gray">- 没有更多了 -</div>
        <div v-for="(item, index) in msgList" :key="index">
          <!-- 系统消息 -->
          <div class="item sys" v-if="item.role == 'sys'">
            <!-- 1)文本类型 -->
            <div v-if="item.contentType == 'text'" class="text-content">
              <p>{{ item.content }}</p>
            </div>
          </div>
          <!-- 客户、客服 -->
          <div v-else class="cu-item" :class="{ self: item.source=='SERVER' }">
            <!-- 头像 -->
            <div class="cu-avatar radius" v-if="item.source=='CLIENT'">
              <el-avatar shape="square" :size="40" :src="clientAvatar" />
            </div>
            <div class="main" :class="{'file': item.contentType == 'image' || item.contentType == 'file'}">
              <!-- 1)上下分类型 -->
              <div v-if="item.contentType == 'balance'" class="content shadow">
                <div>
                  <p class="text margin-right">{{item.content}} 序号{{item.requestId}}</p>
                  <div v-if="item.updownState===0" class="margin-top-sm text-right">
                    <el-button type="primary" size="small" @click="handleAccept(item.requestId)" :disabled="item.updownState!=0">通过</el-button>
                    <el-button type="danger" size="small" @click="handleReject(item.requestId)" :disabled="item.updownState!=0">拒绝</el-button>
                  </div>
                  <div v-else class="margin-top-sm text-right">
                    <el-tag :type="item.updownState===1?'success':'danger'" size="small">
                      {{item.updownState===1?'已通过':'已拒绝'}} 
                    </el-tag>
                    by {{ item.operator }}
                  </div>
                </div>
              </div>
              <!-- 1)文本类型 -->
              <div v-if="item.contentType == 'text'" class="content shadow common_chat_emoji-wrapper-global" :class="{ 'bg-green': item.source=='SERVER' }">
                <p class="text" v-html="$string2emoji(item.content)">{{}}</p>
              </div>
              <div v-else-if="item.contentType == 'faq'" class="content shadow common_chat_emoji-wrapper-global" :class="{ 'bg-green': item.source=='SERVER' }">
                <p class="text">
                  <span v-html="formatHtml(item.content)" @click="handleHtmlImageClick"></span>
                  <span class="text-sm">{{ item.source=='CLIENT'?'[自动问答]':'[自动回复]'}}</span>
                </p>
              </div>
              <!-- 2)图片类型 -->
              <div v-else-if="item.contentType == 'image'" class="shadow">
                <img class="img" style="border-radius:5px;max-width:500px;" :src="computedFileUrl(item)" @click="imgViewDialog_show(computedFileUrl(item))" />
              </div>
              <!-- 3)文件类型 -->
              <div v-else-if="item.contentType == 'file'" class="content shadow">
                <div class="file">
                  <i class="file-icon iconfont fa fa-file"></i>
                  <div class="file-info">
                    <p class="file-name">{{ getFileName(computedFileName(item)) }}</p>
                    <div class="file-opr">
                      <div>
                        <a class="file-download" :href="computedFileUrl(item)" target="_blank" :download="computedFileUrl(item)">下载</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cu-avatar radius" v-if="item.source=='SERVER'">
              <el-avatar shape="square" :size="40" src="/img/server_avatar.jpg" />
            </div>
            <div class="date flex">
              <div v-if="item.source=='CLIENT'">{{chat.userInfo.nickName}}</div>
              <div class="margin-lr-sm">{{item.createTime | chatTime}}</div>
              <div v-if="item.source=='SERVER'">{{userInfo.username}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部区域 -->
      <div class="common_chat-footer">
        <div>
          <!-- 表情、文件选择等操作 -->
          <div class="padding-lr-sm padding-tb-xs">
            <!-- {{showEmoji}} -->
            <el-popover @v-model="showEmoji" @hide="showEmoji=false" placement="top">
              <emoji-picker
                ref="chat_emoji"
                v-model="content"
                width="600px"
                height="380px"
                @selected="handleEmojiSelected"
              />
              <a slot="reference" class="item" title="发送表情" href="javascript:void(0)" @click="showEmoji = !showEmoji">
                <i class="cuIcon-emoji text-black" style="font-size: 18px"></i>
              </a>
            </el-popover>
            <!-- 文件选择 -->
            <a class="item margin-left-sm" title="发送图片" href="javascript:void(0)" @click="fileUpload_click('file')">
              <i class="cuIcon-pic text-black" style="font-size: 18px"></i>
            </a> 
            <form method="post" enctype="multipart/form-data">
              <input type="file" name="uploadFile" id="common_chat_opr_fileUpload"
                style="display:none;position:absolute;left:0;top:0;width:0%;height:0%;opacity:0;" />
            </form>
          </div>
          <!-- 聊天输入框 -->
          <div>
            <el-input
              id="common_chat_input" 
              v-model="content"
              type="textarea"
              :rows="5"
              maxlength="500"
              @paste.stop="inputContent_paste"
              @keydown="inputContent_keydown"
              @mouseup="inputContent_mouseup"
              @mouseleave="inputContent_mouseup"
              @keyup.enter.native="sendText">
            </el-input>
          </div>
          <div class="flex justify-end align-center">
            <!-- 快捷回复 -->
            <el-dropdown
              split-button
              type="primary"
              placement="top"
              trigger="click"
              class="margin-right"
              @click="sendText"
              @command="handleQuickReply">
              发送
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in quickReplyItems" :key="item.id" :command="item.content">
                  {{ item.content }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 发送按钮 -->
            <!-- <el-button type="primary" size="small" class="send-btn margin-left" @click="sendText"
              :disabled="content.length == 0">发送
            </el-button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 图片查看dialog -->
    <el-dialog title :visible.sync="imgViewDialogVisible" class="imgView-dialog" :modal="false">
      <div class="header">
        <i class="iconfont fa fa-remove" @click="imgViewDialog_close"></i>
      </div>
      <div class="main">
        <img class="img" :src="imgViewDialog_imgSrc" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { listQuickReply } from '@/api/chat'
import EmojiPicker from '@/components/EmojiPicker'
import { upload } from '@/api/chat'
import { accept, reject } from '@/api/finance/balance'
import { decode } from 'js-base64';
import * as imageConversion from 'image-conversion'
export default {
  components: {
    EmojiPicker
  },
  props: {
    chat: {
      type: Object,
      default: () => {
        return {}
      }
    },
    oprRoleName: {
      required: true,
      type: String,
      default: '',
    }, // 操作者名称；e.g. server:服务端、client:客服端
  },
  data() {
    return {
      loading: false,
      finished: false,
      limit: 20,
      loaded: false,
      showEmoji: false,
      content: '',
      inputContent_setTimeout: null, // 输入文字时在输入结束才修改具体内容
      shortcutMsgList: [], // 聊天区域的快捷回复列表
      imgViewDialogVisible: false, // 图片查看dialog的显示
      imgViewDialog_imgSrc: '', // 图片查看dialog的图片地址
      imgSize: 200,
      quickReplyItems: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.token.userInfo
    }),
    clientAvatar () {
      if (this.chat.avatar && this.chat.avatar != '') {
        return this.chat.avatar
      }
      return '/img/client_avatar.jpg'
    },
    msgList () {
      return this.$store.getters.GET_MSG_LIST[this.chat.uid]
    },
    newMsgTime () {
      return this.$store.getters.GET_NEW_MSG_TIME[this.chat.uid]
    },
  },
  watch: {
    'newMsgTime': {
      handler: function (val) {
        this.goEnd()
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.init();
    });
  },
  methods: {
    handleEmojiSelected () {
      console.log('handleEmojiSelected')
      this.showEmoji = false
    },
    computedFileUrl(item) {
      if (item.contentType == 'file' || item.contentType == 'image') {
        let content = JSON.parse(item.content)
        return '/api/static/chat/' + content.fileName
      }
      return null;
    },
    computedFileName(item) {
      if (item.contentType == 'file' || item.contentType == 'image') {
        let content = JSON.parse(item.content)
        return content.fileName
      }
      return null;
    },
    /**
     * 初始化
     * @param {Object} opts 可选对象
     */
    init: function (opts) {
      var that = this;
      listQuickReply().then(res => {
        this.quickReplyItems = res.data
      })
      // that.$refs.qqemoji.$data.faceHidden = true;
      this.$nextTick(function () {
        let chatMain = document.getElementById('common_chat_main')
        // 滚动到底部
        chatMain.scrollTop = chatMain.scrollHeight;
        document.getElementById('common_chat_input').focus();
        // 监听滚动到顶部事件
        chatMain.addEventListener('scroll', this.handleScroll)
      });
      this.loaded = true

    },

    handleScroll (event) {
      const target = event.target
      if (this.loading || this.finished) {
        return
      }
      const scrollTop = target.scrollTop
      const clientHeight = target.clientHeight
      const scrollHeight = target.scrollHeight
      // console.log('scrollTop', scrollTop)
      // console.log('clientHeight', clientHeight)
      // console.log('scrollHeight', scrollHeight)
      if (scrollTop === 0) {
        this.loadMore()
      }
    },

    loadMore () {
      const that = this
      that.loading = true
      that.$store.dispatch('loadMoreMessage', {
        uid: that.chat.uid,
        start: that.msgList.length,
        limit: that.limit
      }).then(res => {
        that.loading = false
        if (res.data.length < that.limit) {
          console.log('res.data.length', res.data.length)
          that.finished = true
        } else {
          that.finished = false
        }
        console.log('load more', res.data.length, that.limit)
      })
    },

    /**
     * 发送文本
     */
    sendText: function () {
      let that = this
      if (this.content.length == '') {
        return;
      }
      var msgContent = this.content;
      this.sendMsg({
        uid: that.chat.uid,
        contentType: 'text',
        content: msgContent,
      });
      this.content = ''
    },

    handleQuickReply (content) {
      this.content = content
      this.sendText()
    },

    /**
     * 输入框的mouseup
     */
    inputContent_mouseup: function (e) {
    },

    /**
     * 输入框的keydown
     */
    inputContent_keydown: function (e) {
      // keyup触发时，绑定的数据还没有被变更，需要进行延后访问
      clearTimeout(this.$data.inputContent_setTimeout);
      this.$data.inputContent_setTimeout = setTimeout(() => {
        // 若按了回车，直接发送
        if (e.keyCode == 13) {
          this.sendText();
        }
      }, 1);
    },

    /**
     * 输入框的粘贴
     */
    inputContent_paste: function (e) {
    
    },

    formatHtml (c) {
      if (!c) {
        return ''
      }
      let article = c.replace(/(\<img|\<p)/gi, function ($0, $1) {
        return {
          "<img": '<img class="img" style="max-width:100%;height:auto;display:block;border-radius:6px;")"',
          "<p": '<p style="line-height:22px" ',
          "<article":"<div",
          "</article": "</div",
          "<header": "<div",
          "</header": "</div"
        }[$1];
      });
      return article
    },
    handleHtmlImageClick (event) {
      const target = event.target;
      // 检查点击的元素是否为图片
      if (target.tagName.toLowerCase() === 'img') {
        // 执行图片点击后的操作
        console.log('Image clicked', target.src);
        this.imgViewDialog_show(target.src)
      }
    },

    /**
     * 文件上传_点击
     */
    fileUpload_click: function (fileType) {
      document.getElementById('common_chat_opr_fileUpload').onchange = this.fileUpload_change;
      document.getElementById('common_chat_opr_fileUpload').click();
    },

    /**
     * 文件上传_选中文件
     */
    fileUpload_change: function (e) {
      const that = this
      var fileNameIndex = document.getElementById('common_chat_opr_fileUpload').value.lastIndexOf('\\') + 1;
      var fileName = document.getElementById('common_chat_opr_fileUpload').value.substr(fileNameIndex);
      var extend = fileName.substring(fileName.lastIndexOf('.') + 1);

      let file = document.getElementById('common_chat_opr_fileUpload').files[0]
      const isImg = (file.type === 'image/jpeg' || file.type === 'image/png')
      // 压缩图片
      if (isImg && file.size > that.imgSize*1000) {
        console.log('图片超出大小，执行压缩')
        imageConversion.compressAccurately(file, that.imgSize).then(res => {
          res = new File([res], file.name, {type: res.type, lastModified: Date.now()})
          console.log('压缩结果', res)
          that.doUpload(res, extend)
        })
      } else {
        if (file.size >= 1000 * 1000 * 5) {
          this.$bvToast.toast('文件大小不能超过5M', {
            title: '提示',
            solid: true,
            variant: 'danger',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
          document.getElementById('common_chat_opr_fileUpload').value = '';
          return false;
        }
        that.doUpload(file, extend)
      }
    },

    // 2.文件上传
    doUpload (file, extend) {
      let that = this
      let formData = new FormData();
      formData.append('file', file);
      upload(formData).then(res => {
        document.getElementById('common_chat_opr_fileUpload').value = '';
        if (res.success) {
          let fileName = decode(res.data).replaceAll('"', '')
          this.sendMsg({
            uid: that.chat.uid,
            contentType: ['png', 'jpg', 'jpeg', 'gif', 'bmp'].indexOf(extend) >= 0 ? 'image' : 'file',
            content: JSON.stringify({fileName: fileName}),
            state: 'success',
          });
        }
      }).finally(res => {
        document.getElementById('common_chat_opr_fileUpload').value = '';
      })
    },

    /**
     * 转换文件名，若文件名称超过9个字符，将进行截取处理
     * @param {String} fileName 文件名称
     */
    getFileName: function (fileName) {
      if (!fileName) {
        return;
      }
      var name = fileName.substring(0, fileName.lastIndexOf('.'));
      var extend = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (name.length > 9) {
        name = name.substring(0, 3) + '...' + name.substring(name.length - 3);
      }
      return name + '.' + extend;
    },

    /**
     * 图片查看dialog_显示
     */
    imgViewDialog_show: function (url) {
      this.$data.imgViewDialogVisible = true;
      this.$data.imgViewDialog_imgSrc = url
    },

    /**
     * 图片查看dialog_显示
     */
    imgViewDialog_close: function () {
      this.$data.imgViewDialogVisible = false;
      var that = this;
      setTimeout(function () {
        that.$data.imgViewDialog_imgSrc = '';
      }, 100);
    },

    /**
     * 发送消息，e.g. 文本、图片、文件
     * @param {Object} msg 消息对象
     */
    sendMsg: function (msg) {
      var that = this;
      // 1.传递
      this.$emit('sendMsg', {
        msg: msg,
        successCallbcak: function () {
          this.content = ''
          document.getElementById('common_chat_input').focus();
          that.goEnd();
        },
      });
    },
    goEnd () {
      let that = this
      this.$nextTick(() => {
        setTimeout(() => {
          console.log('that.$refs', that.$refs)
          document.getElementById('common_chat_main').scrollTop = document.getElementById('common_chat_main').scrollHeight;
        }, 100);
      });
    },
    handleAccept (requestId) {
      this.$confirm('确认通过？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        accept (requestId, '').then(res => {
          if (res.success) {
            this.$store.dispatch('openNewChat', this.chat.uid)
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          }
        })
      })
    },
    handleReject (requestId) {
      this.$confirm('确认拒绝？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        reject (requestId, '').then(res => {
          if (res.success) {
            this.$store.dispatch('openNewChat', this.chat.uid)
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          }
        })
      })
    }
  },
};
</script>
<style lang="less">
.common_chat-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  float: left;
  border: 0px;

  .common_chat-inner {
    width: 100%;
    height: 100%;
    .msg-list {
      padding: 15px;
      height: calc(~'100% - 250px');
      overflow-y: auto;
    }

    .common_chat-footer {
      position: relative;
      width: 100%;
      border-top: 1px solid #ccc;
      .el-dropdown-link {
        cursor: pointer;
        color: #5e72e4;
      }
      .send-btn {
        float: right;
        margin-right: 15px;
        &.off,
        &.end {
          background-color: #ccc;
          border-color: #ccc;
        }
      }

      .off-wrapper {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        font-size: 14px;

        .content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.imgView-dialog {
  background: #00000080;
  height: 100%;

  .el-dialog {
    max-width: 75%;
    position: relative;
    background: transparent;
    box-shadow: none;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0px;
      text-align: center;
      position: relative;

      .header {
        text-align: right;
        position: relative;
        height: 0px;

        .fa-remove {
          font-size: 32px;
          color: white;
          position: relative;
          right: -50px;
          top: -30px;
          cursor: pointer;
        }
      }

      .main {
        .img {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

