import axios from 'axios'
import store from '@/store'
import router from '@/routes/router'
import {getProtocol, getHost, getPort} from "@/utils/UrlUtils"
import { removeToken } from '@/utils/auth'
import swal from 'sweetalert2';

// 请求延迟
const REQUEST_TIMEOUT = 50000
// 消息延迟
const MSG_TIMEOUT = 3 * 1000
// 协议请求头
const PROTOCOL_HEADER = getProtocol(window.location.href)
// Socket 协议头
const SOCKET_HEADER = PROTOCOL_HEADER === 'http' ? 'ws' : 'wss'
// 【部署时可能需要修改处1/2】服务器域名/ip：指定值或从浏览器地址栏获取
const SERVER_DOMAIN = getHost(window.location.href)
// 【部署时可能需要修改处2/2】服务器端口：指定值或从浏览器地址栏获取
// 强烈建议除本地开发环境外，部署环境一律通过Nginx配置反向代理使前后台端口一致
//const SERVER_PORT = ':8888'
const SERVER_PORT = getPort(window.location.href)
const PAGE_PORT = getPort(window.location.href)
// 项目环境路径
const CONTEXT_PATH = 'api'
// 网页地址，不包括api后缀
const PAGE_URL = `${PROTOCOL_HEADER}://${SERVER_DOMAIN}${PAGE_PORT}/`
console.log('PAGE_URL='+PAGE_URL)
// 接口地址，包括api后缀
const BASE_URL = `${PROTOCOL_HEADER}://${SERVER_DOMAIN}${SERVER_PORT}/${CONTEXT_PATH}/`
console.log('BASE_URL='+BASE_URL)
// WebSocket 地址
const WEB_SOCKET_URL = `${SOCKET_HEADER}://${SERVER_DOMAIN}${SERVER_PORT}/${CONTEXT_PATH}/ws/`
const CHAT_SOCKET_URL = `${SOCKET_HEADER}://${SERVER_DOMAIN}${SERVER_PORT}/${CONTEXT_PATH}/chat-ws/`
// 后缀
const SUFFIX = ""
// 全局头部信息
axios.defaults.headers.common['X-Custom-Header'] = 'foobar';
axios.defaults.withCredentials=true
// 表单提交实例
const formFetch = axios.create({
  baseURL: BASE_URL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  transformRequest: [function (data) {
    return data
  }]
})
// Json提交实例
const jsonFetch = axios.create({
  baseURL: BASE_URL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})
// 文件上传实例
const uploadFetch = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60,
  withCredentials: true, //跨域携带cookie
  headers: {
    'Content-Type': 'multipart/form-data',
  }
})

// 请求拦截器处理
function requestInterceptors (config) {
  if (store.getters.GET_TOKEN) {
    config.headers['access-token'] = store.getters.GET_TOKEN
  }
  return config
}
function requestInterceptorsError (error) {
  return Promise.reject(error)
}
// 响应拦截器
function responseInterceptors (response) {
  store.dispatch('system/UnLoading')
  if (response.headers['content-type'] 
    && response.headers['content-type'].indexOf('octet-stream') != -1) {
    const filename = response.headers['content-disposition'].split('filename=')[1]
    downloadFile(response.data, decodeURIComponent(escape(filename)))
  } else {
    const res = response.data
    if (res.success) {
      return response.data
    } else {
      swal.fire({
        title: res.msg ? res.msg : '操作失败',
        buttonsStyling: false,
        type: 'warning',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
      return response.data
    }
  }
}
function responseInterceptorsError (error) {
  store.dispatch('system/UnLoading')
  if (error.response) {
    // The request was made and the server responded with a status code
    // console.log('error.response.config.url:', error.response.config.url)
    // console.log('error.response.status:'+error.response.status)
    // console.log('error.response.data.status:'+error.response.data.status)
    if (error.response.status==401) {
      console.log('401')
      removeToken()
      try {
        router.push('/login')
      } catch(e) {
        console.error(e)
      }
    } else {
      swal.fire({
        title: '接口请求失败：'+error.response.status,
        buttonsStyling: false,
        type: 'warning',
        confirmButtonClass: 'btn btn-success btn-fill'
      });
    }
  } else if (error.request) {
    // The request was made but no response was received
    swal.fire({
      title: '网络连接失败',
      buttonsStyling: false,
      type: 'warning',
      confirmButtonClass: 'btn btn-success btn-fill'
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    swal.fire({
      title: '网络连接失败',
      buttonsStyling: false,
      type: 'warning',
      confirmButtonClass: 'btn btn-success btn-fill'
    });
  }
  return Promise.reject(error)
}

// 请求拦截器
jsonFetch.interceptors.request.use(requestInterceptors, requestInterceptorsError)
formFetch.interceptors.request.use(requestInterceptors, requestInterceptorsError)
uploadFetch.interceptors.request.use(requestInterceptors, requestInterceptorsError)
// 响应拦截器
jsonFetch.interceptors.response.use(responseInterceptors, responseInterceptorsError)
formFetch.interceptors.response.use(responseInterceptors, responseInterceptorsError)
uploadFetch.interceptors.response.use(responseInterceptors, responseInterceptorsError)
export { formFetch, jsonFetch, uploadFetch, BASE_URL, PAGE_URL, WEB_SOCKET_URL, CHAT_SOCKET_URL }
