import { signIn, signOut, getAccount } from '@/api/auth'
import { setToken, getToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'
const state = {
  // 令牌
  token: getToken(),
  openidToken: '',
  bindAgent: null,
  userInfo: {},
  navMenu: [],
  currentTittle: ''
}

const mutations = {
  // 设置令牌
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_OPENID_TOKEN (state, openidToken) {
    state.openidToken = openidToken
  },
  SET_BIND_AGENT (state, bindAgent) {
    state.bindAgent = bindAgent
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_NAVMENU: (state, navMenu) => {
    state.navMenu = navMenu
  },
  SET_CURRENT_TITTLE: (state, tittle) => {
    state.currentTittle = tittle
  },
}
const actions = {
  SetUserInfo ({ commit }, userInfo) {
    commit('SET_USERINFO', userInfo)
  },
  // 普通登录
  SignIn ({ commit }, loginForm) {
    return new Promise((resolve, reject) => {
      signIn(loginForm.username, loginForm.password, loginForm.captcha)
        .then(res => {
          if (res.success) {
            let cookie = document.cookie
            // console.log('cookie', cookie)
            let token = Cookies.get('satoken-admin')
            if (token) {
              //console.log('token', token)
              setToken(token)
              commit('SET_TOKEN', token)
              resolve()
            } else {
              reject(res)
            }
          } else {
            reject(res)
          }
        })
    })
  },
  // 登出
  SignOut ({ commit, state }) {
    return new Promise((resolve, reject) => {
      signOut(state.token)
        .then(() => {
          commit('SET_TOKEN', null)
          commit('SET_USERINFO', null)
          commit('SET_NAVMENU', null)
          localStorage.clear()
          removeToken()
          resolve()
        }).catch(() => {
        removeToken()
        resolve()
      })
    })
  },
  // 刷新账户信息
  GetAccount ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getAccount()
        .then(res => {
          if (res.success) {
            commit('SET_USERINFO', res.data.userInfo)
            commit('SET_NAVMENU', res.data.navMenu)
            resolve(res)
          } else {
            reject(res.message)
          }
        }).catch(() => {
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
